import React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import { Link } from 'gatsby-theme-material-ui';

import {
    faLinkedin,
    faMedium,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    Box,
    Divider,
    IconButton,
    Link as MuiLink,
    Typography,
} from '@mui/material';

function FooterComp(props) {
    const {
        interviewPosts,
        retroPosts,
        roadmapPosts,
        conceptPosts,
    } = props.data;

    return (
        <Box sx={{ mt: 3, p: 3 }}>
            <Divider />
            <footer>
                <Box
                    sx={{
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: {
                            xs: 'column',
                            md: 'row',
                        },
                    }}
                >
                    <Box sx={{ my: { xs: 1, md: 0 } }}>
                        {renderResourceList(
                            'Sprint Retrospective Resources',
                            retroPosts
                        )}
                    </Box>
                    <Box sx={{ my: { xs: 1, md: 0 } }}>
                        {renderResourceList(
                            'Product Manager Interview Resources',
                            interviewPosts
                        )}
                        <Box sx={{ my: { xs: 1, md: 0 } }}>
                            {renderResourceList(
                                'Product Management Concepts Resources',
                                conceptPosts
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ my: { xs: 1, md: 0 } }}>
                        {renderResourceList(
                            'Product Roadmap Management Resources',
                            roadmapPosts
                        )}
                        <Divider sx={{ my: 1 }} />
                        <Link to="/pm-course/" underline="none">
                            <Typography variant="caption" color="textSecondary">
                                <strong>
                                    Product Management Fundamentals
                                    Certification Course
                                </strong>
                            </Typography>
                        </Link>
                        <Divider sx={{ my: 1 }} />
                        {[
                            ['mailto:hello@dezkr.com', faEnvelope],
                            [
                                'https://www.linkedin.com/company/dezkr/about/',
                                faLinkedin,
                            ],
                            [
                                'https://www.youtube.com/channel/UCUtVjU4OguGEe26LSMAe8aQ',
                                faYoutube,
                            ],
                            ['https://medium.com/@dezkr', faMedium],
                        ].map(([href, icon], index) => (
                            <IconButton
                                color="primary"
                                key={index}
                                href={href}
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={icon} />
                            </IconButton>
                        ))}
                        <br />

                        <MuiLink
                            component="a"
                            href="mailto:hello@dezkr.com"
                            underline="none"
                            variant="caption"
                            color="textSecondary"
                        >
                            Contact Us
                        </MuiLink>
                        <br />
                        <Link to="/credits" underline="none">
                            <Typography variant="caption" color="textSecondary">
                                Credits
                            </Typography>
                        </Link>
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            Copyright ©2021 Dezkr
                        </Typography>
                    </Box>
                </Box>
            </footer>
        </Box>
    );
}

function renderResourceList(listTitle, listItems) {
    return (
        <>
            <Typography variant="caption" color="textSecondary">
                <strong>{listTitle}</strong>
            </Typography>
            <br />
            {listItems?.nodes.map(
                ({ id, fields: { slug }, frontmatter: { title } }) => (
                    <Link key={id} to={`/blog${slug}`} underline="none">
                        <Typography variant="caption" color="textSecondary">
                            {title} <br />
                        </Typography>
                    </Link>
                )
            )}
        </>
    );
}

export default function Footer() {
    return (
        <StaticQuery
            query={graphql`
                query PostsQuery {
                    interviewPosts: allMarkdownRemark(
                        filter: {
                            frontmatter: {
                                tags: { in: "Product Management Interviews" }
                            }
                        }
                        sort: { order: ASC, fields: frontmatter___date }
                    ) {
                        nodes {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                            }
                        }
                    }
                    retroPosts: allMarkdownRemark(
                        filter: {
                            frontmatter: {
                                tags: { in: "Sprint Retrospective" }
                            }
                        }
                        sort: { order: ASC, fields: frontmatter___date }
                    ) {
                        nodes {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                            }
                        }
                    }
                    roadmapPosts: allMarkdownRemark(
                        filter: {
                            frontmatter: {
                                tags: { in: "Product Roadmap Management" }
                            }
                        }
                        sort: { order: ASC, fields: frontmatter___date }
                    ) {
                        nodes {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                            }
                        }
                    }
                    conceptPosts: allMarkdownRemark(
                        filter: {
                            frontmatter: {
                                tags: { in: "Product Management Concepts" }
                            }
                        }
                        sort: { order: ASC, fields: frontmatter___date }
                        limit: 7
                    ) {
                        nodes {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                            }
                        }
                    }
                }
            `}
            render={data => <FooterComp data={data} />}
        />
    );
}
