import React from 'react';
import { useState } from 'react';

import { CustomPlanContactForm } from '../components/CustomPlanContactForm';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import PricingTable from '../components/PricingTable';
import Seo from '../components/Seo';

import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Pricing(props) {
    const theme = useTheme();
    const sizeMediumUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [showContactForm, setShowContactForm] = useState(false);

    return (
        <Layout>
            <Container maxWidth={sizeMediumUp ? 'md' : 'xs'} sx={{ mb: 20 }}>
                <PricingTable />
            </Container>
            <Footer />
        </Layout>
    );
}

export function Head() {
    return <Seo title="Pricing | Dezkr | Idea Management platform" />;
}
